<template>
  <div v-if="$api.textCategories && texts" class="media-appearences fold-bg-after-white">
    <div class="media-image-wrapper">
      <img src="../../../assets/images/pragmoon.webp" :class="{ smaller: texts.length < 3 }" loading="lazy" alt="Pragmoon interview">
    </div>
    <h2 class="background-heading static right font-brown">
      Napsali o nás
    </h2>
    <div class="container">
      <div class="media-content-wrapper">
        <div class="media-appearences-tiles">
          <MediaAppearanceTile v-for="text in texts" :text="text" :key="text.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaAppearanceTile from '@/views/pages/Homepage/MediaAppearanceTile.vue';

export default {
  name: 'Media',
  components: {
    MediaAppearanceTile,
  },
  computed: {
    texts() {
      return this.$api.textCategories['Napsali o nás'];
    },
  },
};
</script>
