<template>
	<div class="container story">
		<h2 class="background-heading right font-brown">Příběh</h2>
		<div class="row">
			<div class="story-col left col-md-6">
				<div class="story-text" v-html="$api.texts.story_p1.raw"></div>
			</div>
			<div class="story-col right col-md-6">
				<img :src="$api.texts.story_quote1.photos[0]" alt="Petr" loading="lazy">
				<div class="image-caption" v-html="$api.texts.story_quote1.raw"></div>
			</div>
			<div class="story-col left col-md-6">
				<img :src="$api.texts.story_quote2.photos[0]" alt="Roman" loading="lazy">
				<div class="image-caption" v-html="$api.texts.story_quote2.raw"></div>
			</div>
			<div class="story-col right col-md-6">
				<div class="story-text" v-html="$api.texts.story_p2.raw"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Story"
	}
</script>