<template>
  <div class="where-to-buy larger-container larger-container-symetrical" v-if="$api.textCategories && $api.textCategories[categoryId]">
    <h2 class="background-heading static smaller font-brown">
      Kde nás najdete
    </h2>
    <p>
      Síť prodejních míst stále rozšiřujeme.
    </p>
    <p>
      <b>Chcete, aby i u Vás byly naše produkty?</b>
    </p>
    <p>
      Napište nám na <a href="mailto:obchod@koju.cz">obchod@koju.cz</a>, rádi se s Vámi domluvíme.
    </p>
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-4 where-to-buy-card"
             v-for="place in $api.textCategories[categoryId]"
             :key="place.id">
          <component
              :is="parsePlaceData(place.raw).link ? 'a' : 'span'"
              :href="parsePlaceData(place.raw).link"
              :target="parsePlaceData(place.raw).link ? '_blank' : null">
            <img :src="place.photos[0]" :alt="`Logo ${place.name}`" loading="lazy">
            <h3>{{ place.name }}</h3>
            <div v-html="parsePlaceData(place.raw).description"></div>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export const parseLinkFromText = (content) => {
  const regex = /^(?<description>.*)<p><a.*>(?<link>.+)<\/a>.*<\/p>.*$/;

  const match = content.match(regex);

  if (!match) {
    return {
      description: content,
      link: null,
    }
  }

  return match.groups;
};

export default {
  name: "WhereToBuy",
  data() {
    return {
      categoryId: "Kde nás koupíte",
    };
  },
  methods: {
    parsePlaceData(content) {
      return parseLinkFromText(content);
    }
  },
}
</script>
