<template>
	<div class="vouchers">
		<h2 class="background-heading right font-brown">
			Vouchery
		</h2>
		<div class="container" v-if="$api.texts.vouchers">
			<div v-html="$api.texts.vouchers.raw"></div>

			<div class="row" v-if="$api.vouchers">
				<div v-for="voucher in $api.vouchers" :key="voucher.image" class="col-lg-4 voucher">
					<img :src="voucher.image" :alt="voucher.name" loading="lazy">
					<h3 class="italic font-brown">
						{{ voucher.name }}
					</h3>
					<Button color="black" @click.native="toggleCartModal(voucher)">
						Do košíku
					</Button>
				</div>
			</div>
		</div>

		<Modal :open="!!cartModalVisible" @close="toggleCartModal" :loading="sending">
			<template v-slot:title>
				Jakým způsobem si přejete voucher doručit?
			</template>
			<template v-slot:body>
				<div class="col-sm-6">
					<Button color="black" @click.native="addToCart('post')">
						Poštou
					</Button>
				</div>
				<div class="col-sm-6">
					<Button color="black" @click.native="addToCart('email')">
						E-mailem
					</Button>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>

	import Button from "../../components/Button";
	import {EventBus} from "../../../event-bus";
	import Modal from "../../components/Modal";

	export default {
		name: "Vouchers",
		components: {Modal, Button},
		data() {
			return {
				cartModalVisible: null,
				sending: false,
			};
		},
		methods: {
			toggleCartModal(voucher = false) {
				this.cartModalVisible = voucher;
			},

			addToCart(method) {
				this.sending = true;
				this.$api.addToCart(this.cartModalVisible, "voucher", 1, {
					shipping: method
				})
					.then(() => {
						EventBus.$emit("flash", "Voucher úspěšně přidán do košíku");
						this.toggleCartModal();
					})
					.catch(() => {
						EventBus.$emit("flash", "Voucher se nepodařilo přidat do košíku", "error");
					})
					.finally(() => {
						this.sending = false;
					});
			}
		}
	}
</script>