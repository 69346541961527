<template>
  <div class="bg-black try-out larger-container">
    <h2 class="background-heading smaller font-white-semi outline">
      Vyzkoušejte<br>
      <strong>Dark</strong>
    </h2>
    <p class="larger-paragraph">
      Přírodní doplněk stravy s komplexní škálou účinků a vitamínů
    </p>
    <BuyButton color="white" :product="product" size="smaller" :quantity="quantity" @changeQuantity="changeQuantity" />
    <img src="../../../assets/images/dark-try-out.png" :alt="product.name" loading="lazy" />
  </div>
</template>

<script>
import BuyButton from "../../components/BuyButton";
export default {
  name: "TryOut",
  components: {BuyButton},
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    product() {
      return this.$api.products.dark;
    },
  },
  methods: {
    changeQuantity(quantity) {
      this.quantity = quantity;
    },
  }
}
</script>

<style scoped>

</style>
