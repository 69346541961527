<template>
  <component
      :is="data.link ? 'a' : 'span'"
      :href="data.link"
      :target="data.link ? '_blank' : null"
      :rel="data.link ? 'noopener' : null"
      class="media-appearence-tile">
    <h3>{{ text.name }}</h3>

    <div v-html="data.description"></div>

    <img v-if="text.photos[0]" :src="text.photos[0]" alt="Náhled článku" loading="lazy">

    <Button color="white" v-if="data.link">
      Otevřít
    </Button>
  </component>
</template>

<script>
import Button from '../../components/Button';
import { parseLinkFromText } from '@/views/pages/Homepage/WhereToBuy.vue';

export default {
  name: 'MediaAppearanceTile',
  props: {
    text: {
      type: Object,
    },
  },
  components: {
    Button,
  },
  computed: {
    data() {
      return parseLinkFromText(this.text.raw);
    },
  },
};
</script>
