<template>
  <div class="products-overview bg-light-white" :class="'products-overview-' + type" @click.prevent="$emit('toggle-section')">
    <div class="container">
      <div class="grid">
        <div class="grid-description">
          <h2 class="background-heading static font-brown">
            {{ data.name }}
          </h2>
          <p class="larger-paragraph">
            {{ data.stripped }}
          </p>
        </div>
        <div class="grid-effects">
          <EffectsIcons :product="initialProduct" :smaller="true" />
        </div>
        <div class="grid-image">
          <img :src="data.photos[0]" :alt="data.name" :loading="type === 'shots' ? 'eager' : 'lazy'">
        </div>
        <a v-if="!visible" class="toggle-section-button">
          <span>produkty</span>
          <span class="arrow-icon">↓</span>
        </a>
        <a v-else class="toggle-section-button">
          <span>&nbsp;</span>
          <span class="arrow-icon">↑</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import EffectsIcons from '@/views/pages/Product/EffectsIcons.vue';

export default {
  name: 'ProductsOverview',
  props: {
    type: {
      type: String,
      default: 'shots',
    },
    visible: {
      type: Boolean,
      default: false,
    }
  },
  components: { EffectsIcons },
  computed: {
    initialProduct() {
      return this.type === 'shots' ? Object.values(this.$api.shortProducts)[0] : Object.values(this.$api.shortProducts).filter(p => p.is_syrup)[0];
    },
    data() {
      return this.$api.texts[`homepage-produkty-${this.type === 'shots' ? 'ginger-shoty' : 'sirupy'}`];
    },
  },
};
</script>
