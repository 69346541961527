<template>
  <div class="why-linear">
    <h2 class="background-heading right static font-black-semi">
      Proč užívat?
    </h2>
    <div class="why-linear-grid">
      <section class="why-linear-block one">
        <Icon src="immunity" path="images/product-icons" />
        <h3>
          Posiluje imunitu
        </h3>
        <ul>
          <li>
            má <strong>antioxidační a protizánětlivé účinky</strong>,
            díky kterým dokáže imunitní systém
            lépe reagovat na možná nebezpečí
          </li>
        </ul>
      </section>
      <Icon class="arrow-one arrow-mobile" src="arrow1" path="images/why" />
      <section class="why-linear-block two">
        <Icon src="workout" path="images/product-icons" />
        <h3>
          Pomůže při tréninku
        </h3>
        <ul>
          <li>
            <strong>zrychlí krevní oběh</strong> a <strong>nabudí</strong> před tréninkem
          </li>
          <li>
            <strong>zvýší tělesnou teplotu</strong> a <strong>pocení</strong>, které
            způsobuje vyšší vyplavování endorfinů, díky
            tomu se po cvičení cítíte lépe
          </li>
          <li>
            napomáhá <strong>svalové regeneraci</strong>
          </li>
        </ul>
      </section>
      <Icon class="arrow-two arrow-mobile" src="arrow1" path="images/why" />
      <section class="why-linear-block three">
        <Icon src="heart" path="images/product-icons" />
        <h3>
          Pozitivně ovlivní činnost srdce
        </h3>
        <ul>
          <li>
            při pravidelném užívání pomáhá <strong>snižovat
            krevní tlak</strong> a zlepšuje krevní oběh
          </li>
          <li>
            <strong>snižuje hladinu cukru</strong> v krvi a zároveň
            snižuje cholesterol
          </li>
        </ul>
      </section>
      <Icon class="arrow-three arrow-mobile" src="arrow1" path="images/why" />
      <section class="why-linear-block four">
        <Icon src="stomach" path="images/product-icons" />
        <h3>
          Napomáhá trávení
        </h3>
        <ul>
          <li>
            při vypití po těžkém jídle <strong>zrychlí trávicí
            soustavu</strong> a napomůže k pocitu lehkosti
          </li>
        </ul>
      </section>
      <Icon class="arrow-one arrow-desktop" src="arrow2" path="images/why" />
      <Icon class="arrow-two arrow-desktop" src="arrow1" path="images/why" />
      <Icon class="arrow-three arrow-desktop" src="arrow1" path="images/why" />
    </div>
  </div>
</template>

<script>
import Icon from "../../components/Icon";

export default {
  name: "WhyLinear",
  components: {Icon}
}
</script>
