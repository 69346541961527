<template>
  <div class="how-to-use larger-container">
    <h2 class="background-heading static outline">
      Jak užívat?
    </h2>
    <div class="row">
      <div class="how-image col-md-5 col-lg-4 col-xl-5">
        <img src="../../../assets/images/how-to-use.webp" loading="lazy" alt="Drinking a ginger shot">
      </div>
      <div class="col-md-7 col-lg-8 col-xl-7">
        <div class="row how-row">
          <div class="col-sm-3 col-md-12 col-lg-3">
            <h3>
              Kdy?
            </h3>
          </div>
          <div class="col-sm-9 col-md-12 col-lg-9">
            <ul>
              <li>
                <b>Ráno na lačno</b> - okamžité nabuzení organizmu
              </li>
              <li>
                <b>Po těžkém jídle</b> - zrychlí se trávení jídla
              </li>
              <li>
                <b>Před tréninkem</b> - nakopnutí k lepšímu výkonu
              </li>
            </ul>
          </div>
        </div>
        <div class="row how-row">
          <div class="col-sm-3 col-md-12 col-lg-3">
            <h3>
              Kolik?
            </h3>
          </div>
          <div class="col-sm-9 col-md-12 col-lg-9">
            <ul>
              <li class="single">
                <b>Jeden shot (40 ml) denně.</b> Ideálně 3-4x týdně.
              </li>
            </ul>
          </div>
        </div>
        <div class="row how-row">
          <div class="col-sm-3 col-md-12 col-lg-3">
            <h3>
              Jak?
            </h3>
          </div>
          <div class="col-sm-9 col-md-12 col-lg-9">
            <ul>
              <li>
                <b>Na ex</b> - prostě vypít celou menší lahvičku naráz
              </li>
              <li>
                <b>Smíchat s vodou/čajem/limonádou</b> - doporučujeme naši Woody sodu
              </li>
              <li>
                <b>Alkoholický/nealkoholický drink</b> <!--- recepty najdete <router-link :to="{ name: 'Recipes' }">zde</router-link>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="row how-bottom">
          <div class="col-12">
            <h3>Další informace</h3>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 how-bottom-col">
            <Icon src="cold" />
            <p>Ginger shoty skladujte v chladu.</p>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 how-bottom-col">
            <Icon src="shake" />
            <p>Před použitím důkladně protřepejte.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../components/Icon";

export default {
  name: "HowToUse",
  components: {Icon}
}
</script>

<style scoped>

</style>